import React, { useState, useEffect } from "react";
import "./footer.css";
import faceBook from "../../assets/socialIcons/facebook.png";
import instagram from "../../assets/socialIcons/instagram.png";
import youtube from "../../assets/socialIcons/youtube.png";
import tiktok from "../../assets/socialIcons/tiktok.png";
import map_1 from "../../assets/location-pin.png";
import { API_URLS } from "../api";

const Footer = ({mapLocation}) => {
  const [insta, setinsta] = useState([]);
  const [face, setFace] = useState([]);
  const [tikTok, setTikTok] = useState([]);
  const [youTube, setYouTube] = useState([]);
  const [map, setMap] = useState([]);
  const instagram_api = API_URLS.instagram;
  const facebook_api = API_URLS.facebook;
  const tiktok_api = API_URLS.tiktok;
  const youtube_api = API_URLS.youtube;
  const map_api = API_URLS.brands;


  const instagramReq = () => {
    fetch(instagram_api)
      .then((res) => res.json())
      .then((data) => {
        setinsta(data);
      }, []);
  };
  const facebookReq = () => {
    fetch(facebook_api)
      .then((res) => res.json())
      .then((data) => {
        setFace(data);
      }, []);
  };
  const tiktokReq = () => {
    fetch(tiktok_api)
      .then((res) => res.json())
      .then((data) => {
        setTikTok(data);
      }, []);
  };
  const setYouTubeReq = () => {
    fetch(youtube_api)
      .then((res) => res.json())
      .then((data) => {
        setYouTube(data);
      }, []);
  };
  const mapReq = () => {
    fetch(map_api)
      .then((res) => res.json())
      .then((data) => {
        setMap(data.data);
      }, []);
  };

  useEffect(() => {
    instagramReq();
    facebookReq();
    tiktokReq();
    setYouTubeReq();
    mapReq();
  }, []);

  return (
    <div className="footer">
      <h2 className="title">- Continue with -</h2>
      <div className="icons">
        {<a target="blank" href={face.data}><img src={ faceBook} width={50} alt="instagram" /></a>}
        {<a target="blank" href={insta.data}><img src={instagram} width={50} alt="instagram" /></a>}
        {<a target="blank" href={tikTok.data}><img src={tiktok} width={50} alt="instagram" /></a>}
        {<a target="blank" href={youTube.data }><img src={youtube} width={50} alt="youtube" /></a>}
        {map.length > 0 && (
          <a target="blank" href={mapLocation}><img src={map_1} width={50} alt="map" /></a>
        )}
      </div>
    </div>
  );
};

export default Footer;
