import React, { useEffect, useState } from "react";
import Footer from "../components/footer/Footer";
import { Link, useParams } from "react-router-dom";
import "./home.css";
import Loader from "../components/loader/Loader"; // Assuming you have a Loader component
import { API_URLS } from "../components/api";

const Home = () => {
  const { slug } = useParams();
  console.log(slug);
  const [homeData, setHomedata] = useState([]);
  const [loading, setLoading] = useState(true);
  const api_data = `${API_URLS.brands}/${slug}`;

  useEffect(() => {
    fetch(api_data)
      .then((response) => response.json())
      .then((data) => {
        setHomedata(data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [api_data]);

  if (loading) {
    return <Loader />;
  }

  return (
    <section
      className="home_page"
      style={{ backgroundColor: homeData.bg_color, color: homeData.text_color }}
    >
      <div className="App">
        <img className="home_logo" src={homeData.logo} alt="Company logo" />
        <p className="Page_Desc"> {slug}</p>
        <div className="boxes">
          <a
            href={homeData.menu_link}
            target="blank"
            className="link"
            style={{ color: homeData.text_color, borderColor: homeData.text_color }}
          >
            {homeData.menu_label}
          </a>
          <Link
            to={`/:${slug}/review`}
            className="link"
            style={{ color: homeData.text_color, borderColor: homeData.text_color  }}
          >
            full Review
          </Link>
          <Link
            to={`/:${slug}/offer`}
            className="link"
            style={{ color: homeData.text_color, borderColor: homeData.text_color  }}>
            offers
          </Link>
        </div>
        <div className="contactUs">
          <Footer mapLocation={homeData.location} />
        </div>
      </div>
    </section>
  );
};

export default Home;
