const API_BASE_URL = "https://nawrt.com/api/public/api/home";

export const API_URLS = {
  brands: `${API_BASE_URL}/brands`,
  offers: `${API_BASE_URL}/offers`,
  questions: `${API_BASE_URL}/get-questions`,
  review: `${API_BASE_URL}/store-review`,
  // social media: `${API_BASE_URL}/
  instagram: `${API_BASE_URL}/redirect/instagram`,
  facebook: `${API_BASE_URL}/redirect/facebook`,
  tiktok: `${API_BASE_URL}/redirect/tiktok`,
  youtube: `${API_BASE_URL}/redirect/youtube`
};