import React, {useState, useEffect} from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./home/Home";
import Review from "./review/Review";
import Offer from "./offer/Offer";
import Pages from "./pages/Pages";
import Loader from "./components/loader/Loader";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loading ? <Loader /> :
      <Routes>
        <Route path="/" element={<Pages />} />
        <Route path="/:slug" element={<Home />} />
        <Route path="/:slug/review" element={<Review />} />
        <Route path="/:slug/offer" element={<Offer />} />
      </Routes>
    }
    </div>
  );
}
export default App;