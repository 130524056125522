import React, { useState, useEffect } from "react";
import "./offers.css";
import Nav from "../components/nav.control/Nav";
import logo from "../assets/logo.png";
import { API_URLS } from "../components/api";

const Product = () => {
  const [offer, setOffer] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState(true);
  const [activePopup, setActivePopup] = useState(false);

  const api_offers = API_URLS.offers;
  const api_brands = API_URLS.brands;
  useEffect(() => {
    fetch(api_offers)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data.data); off
        setOffer(data.data);
        setLoading(false);
      });
  }, []);

  // partetions contact
  useEffect(() => {
    fetch(api_brands)
      .then((res) => res.json())
      .then((data) => setContact(data.data));
  }, []);

  // handle popup contact on click
  const handlePopup = () => {
    setActivePopup(!activePopup);
  };

  const offerBox = offer.map((item, index) => {
    return (
      <div className="box" key={index}>
        <img className="product_image" src={item.menu_photo} alt="" />
        <div className="product_info">
          <div className="name_percent">
            <h3>{item.name}</h3>
            <div className="price_info">
              <p> {"جنيه " + item.original_price}</p>
              <p>نسبه الخصم {"%" + item.offer_percent}</p>
            </div>
          </div>
          <button className="take_now" onClick={handlePopup}>
            اطلب الان
          </button>
        </div>
      </div>
    );
  });

  return (
    <div className="pgae_offers" style={{paddingBottom: "30px"}}>
      <Nav />
      {loading ? (
        <div className="loading">
          <img
            className="loader_logo"
            src={logo}
            width={500}
            alt="لوجو مطعم ابن الشام"
          />
        </div>
      ) : (
        <div className="boxes">{offerBox}</div>
      )}
      <div
        className="allPartetions_contact_boxes"
        id={activePopup ? "active" : ""}
      >
        <button className="btn_colse" onClick={() => setActivePopup(false)}>
          close
        </button>
        <div className="sections">
          {Array.isArray(contact)
            ? contact.map((brand, index) => (
                <div className="contact_box" key={index}>
                  <h3 style={{color: "red"}}>{brand.name}</h3>
                  <div className="contact">
                    <a href={`https://wa.me/${brand.phone}`} target="blank">
                      whatsApp
                    </a>
                    <a href={`tel:${brand.phone}`} target="blank">
                      Call
                    </a>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default Product;
