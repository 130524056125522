import React, { useState, useEffect } from "react";
import "./review.css";
import Nav from "../components/nav.control/Nav";
import Swal from "sweetalert2";
import { API_URLS } from "../components/api";
import { useParams } from "react-router-dom";
import Rating from "react-rating-stars-component"; // Import the rating component

const Review = () => {
  const [question, setQuestion] = useState([]); // state get
  const [QuestionOne, setQuestionOne] = useState(0);
  const [QuestionTwo, setQuestionTwo] = useState(0);
  const [QuestionThree, setQuestionThree] = useState(0);
  const [QuestionFour, setQuestionFour] = useState(0);
  const [QuestionFive, setQuestionFive] = useState(0);
  const [QuestionSex, setQuestionSex] = useState(0);
  const [QuestionSeven, setQuestionSeven] = useState(0);
  const [QuestionEight, setQuestionEight] = useState(0);
  const [QuestionNine, setQuestionNine] = useState(0);
  const [QuestionTen, setQuestionTen] = useState(0);
  const [name, setName] = useState("");
  const [phone, setphone] = useState("");
  const [question_numbers, setquestion_numbers] = useState("");
  const [brand_id, setbrand_id] = useState("0");

  const [offer, setoffer] = useState(""); // offer code
  const [offer_persent, setoffer_persent] = useState(""); // offer persent

  /* === get data from DB === */
  const apiGetQuestions = API_URLS.questions;
  useEffect(() => {
    fetch(apiGetQuestions)
      .then((response) => response.json())
      .then((data) => {
        setQuestion(data.data);
      });
  }, []);

  // post
  async function Submit(e) {
    e.preventDefault();
    const isFormValid = QuestionOne
      ? QuestionOne !== ""
      : null && QuestionTwo
      ? QuestionTwo !== ""
      : null && QuestionThree
      ? QuestionThree !== ""
      : null && QuestionFour
      ? QuestionFour !== ""
      : null && QuestionFive
      ? QuestionFive !== ""
      : null && QuestionSex
      ? QuestionSex !== ""
      : null && QuestionEight
      ? QuestionEight !== ""
      : null && QuestionNine
      ? QuestionNine !== ""
      : null && QuestionTen
      ? QuestionTen !== ""
      : null &&
        name !== "" &&
        phone !== "" &&
        question_numbers !== "" &&
        brand_id;

    if (!isFormValid) {
      Swal.fire({
        icon: "error",
        title: "failed...",
        text: "All fields must be filled!",
      });
      return;
    }

    const requestBody = {
      question_1: QuestionOne ? QuestionOne : null,
      question_2: QuestionTwo ? QuestionTwo : null,
      question_3: QuestionThree ? QuestionThree : null,
      question_4: QuestionFour ? QuestionFour : null,
      question_5: QuestionFive ? QuestionFive : null,
      question_6: QuestionSex ? QuestionSex : null,
      question_7: QuestionSeven ? QuestionSeven : null,
      question_8: QuestionEight ? QuestionEight : null,
      question_9: QuestionNine ? QuestionNine : null,
      question_10: QuestionTen ? QuestionTen : null,
      name: name,
      phone: phone,
      question_numbers: question_numbers,
      brand_id: brand_id,
    };

    try {
      Swal.fire({
        // Display loader
        title: "Sending data...",
        html: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const review_data = API_URLS.review;
      const apiResponse = await fetch(review_data, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });
      Swal.close(); // Close loader
      if (apiResponse.status === 201) {
        const responseData = await apiResponse.json();
        setQuestion(responseData.data);
        document.querySelector(".offer_code_popup").style.display = "flex"; // handle display popup
        setoffer(responseData.data.discount_code.code); // offer code
        setoffer_persent(responseData.data.discount_code.offer_percent); // offer percent
      } else if (apiResponse.status === 500) {
        // handle errors
        Swal.fire({
          icon: "error",
          title: "failed...",
          text: "You have to do all the reviews",
        });
      }
    } catch (error) {
      console.error("An error occurred: ", error);
    }
  }

  const [IDSection, setIDSection] = useState("0");
  const { slug } = useParams();
  const cleanedSlug = slug.replace(":", "");
  const api_data = `${API_URLS.brands}/${cleanedSlug}`;
  useEffect(() => {
    fetch(api_data)
      .then((response) => response.json())
      .then((data) => {
        setIDSection(data.data.id);
        setbrand_id(data.data.id)
      })
  }, [api_data]);
console.log('Brand id is: ' + brand_id);
  return (
    <section className="Review">
      <Nav />
      <form className="form_validation" onSubmit={Submit}>
        {question.label_1 && (
          <label className="quistion">
            <label htmlFor="quistion_1">{question.label_1}</label>
            {/* <input
              type="text"
              placeholder="must type question"
              onChange={(e) => setQuestionOne(e.target.value)}
            /> */}
            <Rating
              count={5}
              onChange={(value) => setQuestionOne(value)}
              size={24}
              activeColor="#ffd700"
            />
          </label>
        )}
        {question.label_2 && (
          <label className="quistion">
            <label htmlFor="quistion_1">{question.label_2}</label>
            <Rating
              count={5}
              onChange={(value) => setQuestionTwo(value)}
              size={24}
              activeColor="#ffd700"
            />
          </label>
        )}
        {question.label_3 && (
          <label className="quistion">
            <label htmlFor="quistion_1">{question.label_3}</label>
            <Rating
              count={5}
              onChange={(value) => setQuestionThree(value)}
              size={24}
              activeColor="#ffd700"
            />
          </label>
        )}
        {question.label_4 && (
          <label className="quistion">
            <label htmlFor="quistion_1">{question.label_4}</label>
            <Rating
              count={5}
              onChange={(value) => setQuestionFour(value)}
              size={24}
              activeColor="#ffd700"
            />
          </label>
        )}
        {question.label_5 && (
          <label className="quistion">
            <label htmlFor="quistion_1">{question.label_5}</label>
            <Rating
              count={5}
              onChange={(value) => setQuestionFive(value)}
              size={24}
              activeColor="#ffd700"
            />
          </label>
        )}
        {question.label_6 && (
          <label className="quistion">
            <label htmlFor="quistion_1">{question.label_6}</label>
            <Rating
              count={5}
              onChange={(value) => setQuestionSex(value)}
              size={24}
              activeColor="#ffd700"
            />
          </label>
        )}
        {question.label_7 && (
          <label className="quistion">
            <label htmlFor="quistion_1">{question.label_7}</label>
            <Rating
              count={5}
              onChange={(value) => setQuestionSeven(value)}
              size={24}
              activeColor="#ffd700"
            />
          </label>
        )}
        {question.label_8 && (
          <label className="quistion">
            <label htmlFor="quistion_1">{question.label_8}</label>
            <Rating
              count={5}
              onChange={(value) => setQuestionEight(value)}
              size={24}
              activeColor="#ffd700"
            />
          </label>
        )}
        {question.label_9 && (
          <label className="quistion">
            <label htmlFor="quistion_1" style={{ color: "#820000" }}>
              {question.label_9}
            </label>
            <Rating
              count={5}
              onChange={(value) => setQuestionNine(value)}
              size={24}
              activeColor="#ffd700"
            />
          </label>
        )}
        {question.label_10 && (
          <label className="quistion">
            <label htmlFor="quistion_1">{question.label_10}</label>
            <Rating
              count={5}
              onChange={(value) => setQuestionTen(value)}
              size={24}
              activeColor="#ffd700"
            />
          </label>
        )}
        {/* data sending to back end not show for user */}
        {question.question_numbers && (
          <label className="quistion">
            <input type="hidden" value={question.question_numbers} />
          </label>
        )}
          <label className="quistion">
            <input type="hidden" value={brand_id} />
          </label>
        {/* user information */}
        <div className="userInfo">
          <label>
            <label className="name" htmlFor="user_name">
              your name
            </label>
            <input
              type="text"
              placeholder="Name is required."
              onChange={(e) => setName(e.target.value)}
            />
          </label>
          <label>
            <label className="phone" htmlFor="user_name">user phone</label>
            <input
              type="phone"
              placeholder={"phone is required"}
              onChange={(e) => setphone(e.target.value)}
            />
          </label>
        </div>
        <button className="sentR">Sent Review</button>
      </form>
      <div className={"offer_code_popup"}>
        <h3>كود الخصم</h3>
        <div className="offer_info">
          <p>{offer}</p>
          <p>{offer_persent + "%"}</p>
        </div>
      </div>
    </section>
  );
};
export default Review;