// Loader.js
import React from "react";
import "./loader.css";
import logo from "../../assets/logo.png"

const Loader = () => {
  return (
    <div className="loading_sect">
      <img className="loader" src={logo} alt="لوجو مطعم ابن الشام" />
      <h1>loading...</h1>
    </div>
  );
};

export default Loader;
