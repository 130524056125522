import React, { useEffect, useState } from "react";
import "./pages.css";
import { Link } from "react-router-dom";
import { API_URLS } from "../components/api";
import logo from "../assets/logo.png";

const Pages = () => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const api_brands = API_URLS.brands;

  useEffect(() => {
    fetch(api_brands)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        setBrands(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="loader_sections">
        <img width={200} src={logo} alt="logo" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const brand = brands.map((brand, ind) => {
    return (
      <Link to={brand.slug} className="box" key={ind}>
        {brand.name}
      </Link>
    );
  });

  return (
    <section
      style={{
        backgroundColor: "var(--main_background)",
        height: "170vh",
        paddingTop: "40px",
      }}>
      <div className="pages_content">
        <div className="nav_controll">
          <h1>Welcome to the ibn alsham Restaurant Chain</h1>
          <div className="links"></div>
        </div>
        <div className="pages_boxes">{brand}</div>
      </div>
    </section>
  );
};

export default Pages;
