import React, { useEffect, useState } from "react";
import "./nav.css";
import { Link, useParams } from "react-router-dom";
import bars from "../../assets/bars.png";
import { API_URLS } from "../api";

const Nav = () => {
  const [active, setactive] = useState(false);
  const [homeData, setHomedata] = useState([]);
  const isActive = () => {
    return setactive(!active);
  };
  const { slug } = useParams();
  const cleanedSlug = slug.replace(":", "");

  const api_data = `${API_URLS.brands}/${cleanedSlug}`;
  useEffect(() => {
    fetch(api_data)
      .then((response) => response.json())
      .then((data) => {
        setHomedata(data.data);
      })
  }, []);

  console.log(cleanedSlug);


  return (
    <div className="navgation_controls">
      <Link to={"/"}>ibn alsham</Link>
      <div className="links" id={active ? "active": ""}>
        <Link to={`/`}>Home</Link>
        <a target="blank" href={homeData.menu_link}>{ homeData.menu_label }</a>
        <Link to={`/${slug}/review`}>Review</Link>
        <Link to={`/${slug}/offer`}>Offer</Link>
      </div>
      <div className="bars_btn" onClick={isActive}>
        <img src={bars} alt="" width={30} />
      </div>
    </div>
  );
};

export default Nav;
